<template>
  <v-dialog v-model="active" width="500">
    <template v-slot:activator="{ on: dialogOn }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltipOn }">
          <v-btn :icon="isSmall" :text="!isSmall" v-on="{ ...tooltipOn, ...dialogOn }">
            <span v-if="!isSmall">New List</span>
            <v-icon :right="!isSmall">fal fa-plus</v-icon>
          </v-btn>
        </template>
        <span>Create a New List</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Make New List</v-card-title>
      <v-card-text>
        <v-text-field label="Name of List" v-model="listTitle" outlined />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="active=false">Close</v-btn>
        <v-btn text color="primary" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, computed } from '@vue/composition-api'

export default {
  props: {
    isSmall: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    const listTitle = ref('')
    const active = ref(false)

    const user = computed(() => {
      const user = root.$store.state.user
      if ('spoof' in user) return user.spoof
      return user
    })

    const save = () => {
      root.$feathers.service('directory/list').create({
        type: 'list',
        title: listTitle.value,
        owners: [ user.directoryId ],
        groups: [{ group: 'Group', people: [] }]
      }).then((data) => {
        // console.log(data)
        emit('created', data._id)
        active.value = false
      })
    }

    return { listTitle, active, user, save }
  }
}
</script>
